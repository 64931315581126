@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .Mui-error {
    color: gray !important;
  }

  th,
  td {
    @apply break-words;
  }
  table {
    @apply w-full;
  }
}
